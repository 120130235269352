// StatefulImage.jsx
'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class StatefulImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadState: props.defaultLoadState || 'loaded', // initialize as 'loaded' for SSR
      errorIndex: 0,
    };
  }
  targetRef = React.createRef();

  onLoad = ({ event, targetOnLoad }) => {
    this.setState({ loadState: 'loaded' });
    if (targetOnLoad) {
      targetOnLoad(event);
    }
  };

  componentDidMount() {
    // update loadState for client-side rendering
    const imgElement = this.targetRef.current.querySelector('img');
    let loadState = 'loading';
    // imgElement might be null if LazyImage is not intersecting and rendering an empty <div>.
    if (imgElement) {
      if (imgElement.complete) {
        if (imgElement.naturalWidth !== 0) {
          loadState = 'loaded';
        } else {
          loadState = 'error';
        }
      }
    }
    this.setState({ loadState });
  }

  render() {
    const { loadState, errorIndex } = this.state;
    const { children } = this.props;
    const [target, loading, errors = []] =
      children instanceof Array
        ? ['target', 'loading', 'error'].map(key => {
            if (key === 'error') {
              return children.filter(
                child => key === child.props?.['data-key']
              );
            }
            return children.find(child => key === child.props?.['data-key']);
          })
        : [children];
    return (
      <StyledStatefulImage>
        <OverlayBlock ref={this.targetRef}>
          {React.isValidElement(target) &&
            React.cloneElement(target, {
              onLoad: event =>
                this.onLoad({ targetOnLoad: target.props['onLoad'], event }),
              onError: () => this.setState({ loadState: 'error' }),
              style: {
                ...target.props['style'],
                display: 'error' === loadState ? 'none' : undefined,
              },
            })}
        </OverlayBlock>
        {'loading' === loadState && loading && (
          <OverlayBlock>{loading}</OverlayBlock>
        )}
        {'error' === loadState && errors[errorIndex] && (
          <OverlayBlock>
            {React.cloneElement(errors[errorIndex], {
              onError: () => {
                this.setState({
                  errorIndex: errorIndex + 1,
                });
              },
            })}
          </OverlayBlock>
        )}
      </StyledStatefulImage>
    );
  }
}

StatefulImage.propTypes = {
  defaultLoadState: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const StyledStatefulImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OverlayBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default StatefulImage;
