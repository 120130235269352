// getMediaAsset.js
'use strict';
import { PUBLIC } from './resourceUrlTypeConstants.js';
import getResourceUrl from './getResourceUrl.js';
import getPublicUrlPath from './getPublicUrlPath.js';
import { MESSAGE, USER } from './publicUrlPathConstants.js';

export const MediaPreset = {
  SD: 'sd',
  SD_PREVIEW: 'sd-preview',
};
export const MediaAssetFormat = {
  AVIF: 'avif',
  JPG: 'jpg',
  PNG: 'png',
  WEBP: 'webp',
  MPD: 'mpd',
  M3U8: 'm3u8',
  MP4: 'mp4',
};

/**
 * Get poster url object.
 * @param {string} {id} - id.
 * @param {string} {meId} - me id.
 * @param {string} {userId} - sender id.
 * @param {string} {size} - WxH.
 * @param {MediaAssetFormat} {format} - file format.
 * @param {MediaPreset} {preset} - preset.
 * @return {url} Return url.
 */
export const getPoster = ({
  id,
  assetId,
  meId,
  userId,
  size,
  format,
  preset,
} = {}) => {
  const path = getPublicUrlPath({ publicUrlPathKey: MESSAGE });
  const url = getResourceUrl({
    path,
    endpoint: assetId ? `/${id}/${assetId}/poster.jpg` : `/${id}/poster.jpg`,
    resourceType: PUBLIC,
  });
  if (size) {
    url.searchParams.set('size', size);
  }
  if (format) {
    url.searchParams.set('format', format);
  }
  if (preset) {
    url.searchParams.set('preset', preset);
  }
  if (meId && meId === userId) {
    url.searchParams.set('_', getCacheKey());
  }
  return url;
};

/**
 * Get asset manifest url object.
 * @param {string} {id} - id.
 * @param {string} {assetId} - asset id.
 * @param {MediaAssetFormat} {format} - file format.
 * @param {MediaPreset} {preset} - preset.
 * @return {url} Return url object.
 */
export const getAssetManifest = ({ id, assetId, format, preset } = {}) => {
  const path = getPublicUrlPath({ publicUrlPathKey: MESSAGE });
  const url = getResourceUrl({
    path,
    endpoint: `/${id}${assetId ? `/${assetId}` : ''}/manifest.${format}`,
    resourceType: PUBLIC,
  });
  if (preset) {
    url.searchParams.set('preset', preset);
  }
  return url;
};

/**
 * Get blurred asset manifest url.
 * @param {string} {resourceUrl} - the original manifest url.
 * @returns {string|undefined} return transformed url if applicable.
 */
export const getBlurredAssetManifestUrl = ({ resourceUrl }) => {
  if (!resourceUrl) return;
  const url = new URL(resourceUrl);
  let blurredUrl;
  if (
    url.pathname.match(/\/manifest.(m3u8|mpd)/gi) &&
    url.searchParams.get('preset') === MediaPreset.SD_PREVIEW
  ) {
    url.searchParams.delete('preset');
    url.pathname = url.pathname.replace('/manifest.', '/blurred.');
    blurredUrl = url.href;
  }
  return blurredUrl;
};

/**
 * Get asset trailer url object.
 * @param {string} {id} - id.
 * @param {string} {assetId} - asset id.
 * @param {string} {meId} - me id.
 * @param {string} {userId} - sender id.
 * @param {MediaAssetFormat} {format} - file format.
 * @param {MediaPreset} {preset} - preset.
 * @param {number} {duration} - trailer duration seconds.
 * @return {url} Return url object.
 */
export const getAssetTrailer = ({
  id,
  assetId,
  meId,
  userId,
  format,
  preset,
  duration,
} = {}) => {
  const path = getPublicUrlPath({ publicUrlPathKey: MESSAGE });
  const url = getResourceUrl({
    path,
    endpoint: `/${id}${assetId ? `/${assetId}` : ''}/trailer.${format}`,
    resourceType: PUBLIC,
  });
  if (preset) {
    url.searchParams.set('preset', preset);
  }
  if (duration) {
    url.searchParams.set('duration', duration);
  }
  if (meId && meId === userId) {
    url.searchParams.set('_', getCacheKey());
  }
  return url;
};

let cacheKey = Date.now();

export const setCacheKey = ({ value = Date.now() } = {}) => {
  cacheKey = value;
};

export const getCacheKey = () => cacheKey;

/**
 * Get user picture
 * @param {string} {userId} - user id.
 * @param {string} {meId} - me id.
 * @param {MediaAssetFormat} {format} - media asset format.
 * @param {number} {size} - media asset size.
 * @return {url} Return url.
 */
export const getUserPicture = ({ userId, meId, size, format } = {}) => {
  const path = getPublicUrlPath({ publicUrlPathKey: USER });
  const url = getResourceUrl({
    path,
    endpoint: `/${userId}/picture.jpg`,
    resourceType: PUBLIC,
  });
  if (size) {
    // WxH
    url.searchParams.set('size', `${size}x${size}`);
  }
  if (format) {
    url.searchParams.set('format', format);
  }
  if (meId && meId === userId) {
    url.searchParams.set('_', getCacheKey());
  }
  return url;
};

/**
 * Get user background
 * @param {string} {userId} - user id.
 * @param {string} {meId} - me id.
 * @param {MediaAssetFormat} {format} - media asset format.
 * @param {number} {size} - media asset size.
 * @return {url} Return url.
 */
export const getUserBackground = ({ userId, meId, size, format } = {}) => {
  const path = getPublicUrlPath({ publicUrlPathKey: USER });
  const url = getResourceUrl({
    path,
    endpoint: `/${userId}/background.jpg`,
    resourceType: PUBLIC,
  });
  if (size) {
    // WxH
    url.searchParams.set('size', `${size}x${size}`);
  }
  if (format) {
    url.searchParams.set('format', format);
  }
  if (meId && meId === userId) {
    url.searchParams.set('_', getCacheKey());
  }
  return url;
};

/**
 * Get badge
 * @param {string} {badge} - badge name.
 * @param {MediaAssetFormat} {format} - media asset format.
 * @param {number} {size} - media asset size.
 * @param {string} {path} - prefix pathname.
 * @return {url} Return url.
 */
export const getBadge = ({ badge, size, format, path } = {}) => {
  const url = getResourceUrl({
    path,
    endpoint: `/${badge}.svg`,
    resourceType: PUBLIC,
  });
  if (size) {
    // WxH
    url.searchParams.set('size', `${size}x${size}`);
  }
  if (format) {
    url.searchParams.set('format', format);
  }
  return url;
};
